import React, { useEffect,Suspense } from 'react';
import './App.css';
import Home from './components/home/Home';
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import Footer from './components/footer/Footer';
import AndroidDownload from './components/androidDownload/AndroidDownload';
const TermsAndConditions = React.lazy(() => import('./components/terms&Conditions/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('./components/privacyPolicy/PrivacyPloicy'));
const LegalityLayout = React.lazy(() => import('./components/footerLayout/Legality'));
const ThirteenCardsRummy = React.lazy(() => import('./components/footerContents/thirteencardsrummy/ThirteenCardsRummy'));
const WinMoneyGameApp = React.lazy(() => import('./components/footerContents/winmoneygameapp/WinMoneyGameApp'));
const OnlineRummyCashGames = React.lazy(() => import('./components/footerContents/onlinerummycashgames/OnlineRummyCashGames'));
const PractiseGames = React.lazy(() => import('./components/footerContents/practisegames/PractiseGames'));
const RummyRulesguide = React.lazy(() => import('./components/footerContents/rummyrulesguide/RummyRulesguide'));
const HowToPlayIndianRummyOnlineWithVykaRummy = React.lazy(() => import('./components/footerContents/htpindiarummywithvykarummy/HowToPlayIndianRummyOnlineWithVykaRummy'));
const HowToplayDealsRummyOnVyka = React.lazy(() => import('./components/footerContents/htpdealsrumm,yonvyka/HowToplayDealsRummyOnVyka'));
const HowToPlayPointsRummyOnVykaRummy = React.lazy(() => import('./components/footerContents/htppointsrummyonvykarummy/HowToPlayPointsRummyOnVykaRummy'));
const HoWToPlayPoolRummyOnVykaRummy = React.lazy(() => import('./components/footerContents/htppoolrummyonvykarummy/HoWToPlayPoolRummyOnVykaRummy'));
const GameOfSkills = React.lazy(() => import('./components/footerContents/gameofskills/GameOfSkills'));
const GSTDeductions = React.lazy(() => import('./components/footerContents/gstdeductions/GSTDeductions'));
const TDSOnWinning = React.lazy(() => import('./components/footerContents/tdsonwinning/TDSOnWinning'));
const FAQ = React.lazy(() => import('./components/footerContents/faq/FAQ'));
const AboutLudo = React.lazy(() => import('./components/footerContents/aboutludo/AboutLudo'));
const LudoLegalityLayout = React.lazy(() => import('./components/ludoLayout/LudoLegalityLayout'));
const LudoRules = React.lazy(() => import('./components/footerContents/ludorules/LudoRules'));
const GSTPolicy = React.lazy(() => import('./components/gstPolicy/GSTPolicy'));
const GSTFaq = React.lazy(() => import('./components/gstFaq/GSTFaq'));
const Download = React.lazy(() => import('./components/download/Download'));
const DeactivationPolicy = React.lazy(() => import('./components/deactPolicy/DeactivationPolicy'));
const ResponsibleGaming = React.lazy(() => import('./components/responsiblegaming/ResponsibleGaming'));

function App() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/terms&conditions' element={<TermsAndConditions />} />
          <Route path='/deactivationpolicy' element={<DeactivationPolicy/>}/>
          <Route path='/responsiblegaming' element={<ResponsibleGaming/>}/>
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/gstpolicy' element={<GSTPolicy />} />
          <Route path='/gstfaq' element={<GSTFaq />} />
          <Route path='/download' element={<Download />} />
          <Route path='/androiddownload' element={<AndroidDownload/>}/>
          <Route path='/rummy' element={<LegalityLayout />}>
            <Route path='/rummy/gameofskills' element={<GameOfSkills />} />
            <Route path='/rummy/13cardrummy' element={<ThirteenCardsRummy />} />
            <Route path='/rummy/winmoneygameapp' element={<WinMoneyGameApp />} />
            <Route path='/rummy/onlinerummycashgames' element={<OnlineRummyCashGames />} />
            <Route path='/rummy/practicegamesonvykarummy' element={<PractiseGames />} />
            <Route path='/rummy/rummyrulesguide' element={<RummyRulesguide />} />
            <Route path='/rummy/howtoplayindianrummyonlinewithvykarummy' element={<HowToPlayIndianRummyOnlineWithVykaRummy />} />
            <Route path='/rummy/howtoplaydealsrummyonvyka' element={<HowToplayDealsRummyOnVyka />} />
            <Route path='/rummy/howtoplaypointsrummyonvykarummy' element={<HowToPlayPointsRummyOnVykaRummy />} />
            <Route path='/rummy/howtoplaypollrummyonvyksrummy' element={<HoWToPlayPoolRummyOnVykaRummy />} />
            <Route path='/rummy/gstdeductions' element={<GSTDeductions />} />
            <Route path='/rummy/tdsonwinnings' element={<TDSOnWinning />} />
            <Route path='/rummy/faq' element={<FAQ />} />
          </Route>
          <Route path='/ludo' element={<LudoLegalityLayout />}>
            <Route path='/ludo/aboutludoonline' element={<AboutLudo />} />
            <Route path='/ludo/ludorules' element={<LudoRules />} />
            <Route />
          </Route>
        </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
