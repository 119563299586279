import React, { useEffect } from 'react'

const AndroidDownload = () => {


    useEffect(() => {
        const link = document.createElement("a");
        link.href = "https://gamingprod.s3.ap-south-1.amazonaws.com/apk/vykagames-V1.1.apk"; 
       
        document.body.appendChild(link); 
        link.style.display = "none";
        link.click(); 
        document.body.removeChild(link);  
    
      }, []);
  return (
    <></>
  )
}

export default AndroidDownload
